export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('/codebuild/output/src310981088/src/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/:path(.*)',
    name: 'notfound',
    component: () => import('/codebuild/output/src310981088/src/src/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('/codebuild/output/src310981088/src/src/pages/auth.vue'),
    /* no children */
    meta: {
      "layout": "loading"
    }
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('/codebuild/output/src310981088/src/src/pages/coming-soon.vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/consumer-rights',
    name: 'consumer-rights',
    component: () => import('/codebuild/output/src310981088/src/src/pages/consumer-rights.vue'),
    /* no children */
  },
  {
    path: '/contact-us',
    name: 'public-contact',
    component: () => import('/codebuild/output/src310981088/src/src/pages/contact-us.vue'),
    /* no children */
  },
  {
    path: '/forgot-email',
    name: 'forgot-email',
    component: () => import('/codebuild/output/src310981088/src/src/pages/forgot-email.vue'),
    /* no children */
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/codebuild/output/src310981088/src/src/pages/login.vue'),
    /* no children */
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('/codebuild/output/src310981088/src/src/pages/logout.vue'),
    /* no children */
  },
  {
    path: '/member',
    /* internal name: '/member' */
    /* no component */
    children: [
      {
        path: '',
        name: 'member-home',
        component: () => import('/codebuild/output/src310981088/src/src/pages/member/index.vue'),
        /* no children */
        meta: {
          "layout": "member"
        }
      },
      {
        path: '3b',
        /* internal name: '/member/3b' */
        /* no component */
        children: [
          {
            path: 'classic',
            name: '3b-classic-report',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/3b/classic.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          },
          {
            path: 'smart',
            name: '3b-smart-report',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/3b/smart.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          }
        ],
      },
      {
        path: 'cancel-membership',
        name: 'cancel-membership',
        component: () => import('/codebuild/output/src310981088/src/src/pages/member/cancel-membership.vue'),
        /* no children */
        meta: {
          "layout": "member"
        }
      },
      {
        path: 'contact-us',
        name: 'member-contact',
        component: () => import('/codebuild/output/src310981088/src/src/pages/member/contact-us.vue'),
        /* no children */
        meta: {
          "layout": "member"
        }
      },
      {
        path: 'fraud-insurance',
        /* internal name: '/member/fraud-insurance' */
        /* no component */
        children: [
          {
            path: '',
            name: 'fraud',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/fraud-insurance/index.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          },
          {
            path: 'file-claim',
            name: 'file-claim',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/fraud-insurance/file-claim.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          }
        ],
      },
      {
        path: 'mylona',
        name: 'mylona',
        component: () => import('/codebuild/output/src310981088/src/src/pages/member/mylona.vue'),
        /* no children */
        meta: {
          "layout": "member"
        }
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('/codebuild/output/src310981088/src/src/pages/member/privacy.vue'),
        /* no children */
        meta: {
          "layout": "member"
        }
      },
      {
        path: 'reactivate-membership',
        name: 'reactivate-membership',
        component: () => import('/codebuild/output/src310981088/src/src/pages/member/reactivate-membership.vue'),
        /* no children */
        meta: {
          "layout": "member"
        }
      },
      {
        path: 'settings',
        /* internal name: '/member/settings' */
        /* no component */
        children: [
          {
            path: '',
            name: 'account-settings',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/settings/index.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          },
          {
            path: 'billing',
            name: 'billing-history',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/settings/billing.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          },
          {
            path: 'edit',
            name: 'edit-account',
            component: () => import('/codebuild/output/src310981088/src/src/pages/member/settings/edit.vue'),
            /* no children */
            meta: {
              "layout": "member"
            }
          },
          {
            path: 'payment',
            /* internal name: '/member/settings/payment' */
            /* no component */
            children: [
              {
                path: '',
                name: 'payment-settings',
                component: () => import('/codebuild/output/src310981088/src/src/pages/member/settings/payment/index.vue'),
                /* no children */
                meta: {
                  "layout": "member"
                }
              },
              {
                path: 'new',
                name: 'new-payment',
                component: () => import('/codebuild/output/src310981088/src/src/pages/member/settings/payment/new.vue'),
                /* no children */
                meta: {
                  "layout": "member"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('/codebuild/output/src310981088/src/src/pages/privacy-policy.vue'),
    /* no children */
  },
  {
    path: '/reactivate',
    name: 'account-reactivation',
    component: () => import('/codebuild/output/src310981088/src/src/pages/reactivate.vue'),
    /* no children */
  },
  {
    path: '/service-agreement',
    name: 'terms-of-service',
    component: () => import('/codebuild/output/src310981088/src/src/pages/service-agreement.vue'),
    /* no children */
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('/codebuild/output/src310981088/src/src/pages/signup.vue'),
    /* no children */
    meta: {
      "layout": "signup"
    }
  },
  {
    path: '/welcome',
    name: 'signup-complete',
    component: () => import('/codebuild/output/src310981088/src/src/pages/welcome.vue'),
    /* no children */
  }
]
